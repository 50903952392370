import { useEffect, useState } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useLinkTo } from '@react-navigation/native';
import { Trans, useTranslation } from 'react-i18next';

import { sharedStyles } from '../../config/theme/shared.styles';
import colors from '../../config/theme/shared/colors';
import { RootState } from '../store';
import { ScreenNames } from '../../screens/ScreenNames';
import BATDialog from './common/BATDialog';
import BATText from './common/BATText';
import BATIcon from './BATIcon';

interface OneSignalNoSupportButtonProps {
  currentRouteName?: string;
}

export const OneSignalNoSupportButton = ({ currentRouteName }: OneSignalNoSupportButtonProps) => {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const [showButton, setShowButton] = useState(false);
  const [buttonBottomOffset, setButtonBottomOffset] = useState(0);
  const [showNoSupportForPushNotificationsDialog, setShowNoSupportForPushNotificationsDialog] =
    useState(false);

  const { width } = useWindowDimensions();
  const linkTo = useLinkTo();
  const { t } = useTranslation();

  useEffect(() => {
    setShowButton(!!(userData && userData.ageVerified));
  }, [userData?.consumerId, userData?.ageVerified]);

  useEffect(() => {
    if (!currentRouteName) {
      return;
    }

    setShowButton(!!(userData && userData.ageVerified));
    setButtonBottomOffset(0);

    if ([ScreenNames.howActivatePushNotifications].includes(currentRouteName)) {
      setShowButton(false);
      return;
    }

    if (
      [
        ScreenNames.feed,
        ScreenNames.products,
        ScreenNames.campaignsList,
        ScreenNames.prizeList,
        ScreenNames.wallet,
        ScreenNames.howToEarnPoints,
        ScreenNames.historyPoints,
        ScreenNames.favorites,
        ScreenNames.collections,
      ].includes(currentRouteName)
    ) {
      setButtonBottomOffset(65);
    }

    if ([ScreenNames.onBoarding].includes(currentRouteName)) {
      setButtonBottomOffset(74);
    }

    if ([ScreenNames.tasteProfile].includes(currentRouteName)) {
      setButtonBottomOffset(82);
    }

    if ([ScreenNames.changePassword].includes(currentRouteName)) {
      setButtonBottomOffset(84);
    }

    if ([ScreenNames.redeemCheckout, ScreenNames.insertPackCode].includes(currentRouteName)) {
      setButtonBottomOffset(99);
    }

    if ([ScreenNames.insertBonusCode].includes(currentRouteName)) {
      setButtonBottomOffset(112);
    }
  }, [currentRouteName]);

  return (
    showButton && (
      <>
        <View
          style={[
            sharedStyles.alignItemsCenter,
            sharedStyles.cursorPointer,
            sharedStyles.justifyContentCenter,
            sharedStyles.fixed,
            {
              backgroundColor: '#e54b4d',
              borderRadius: 24,
              bottom: width < 800 ? 16 + buttonBottomOffset : 10,
              height: 48,
              right: width < 800 ? 16 : 10,
              width: 48,
              zIndex: 2147483646,
            },
          ]}>
          <BATIcon
            name="bell"
            style={{
              borderColor: colors.white,
              borderRadius: '50%',
              borderWidth: 1,
              padding: 4,
            }}
            size={28}
            color={colors.white}
            onPress={() => {
              setShowNoSupportForPushNotificationsDialog(true);
            }}
          />
        </View>

        <BATDialog
          visible={showNoSupportForPushNotificationsDialog}
          showAsDrawerOnMobile
          body={
            <View style={sharedStyles.alignItemsCenter}>
              <View
                style={[
                  sharedStyles.alignItemsCenter,
                  sharedStyles.justifyContentCenter,
                  sharedStyles.marginBottomDouble,
                  {
                    backgroundColor: colors.blueDark,
                    borderRadius: 24,
                    height: 48,
                    width: 48,
                  },
                ]}>
                <BATIcon name="notification" color={colors.grey_title} size={32} />
              </View>

              <BATText
                type="heading-4"
                color={colors.grey_title}
                customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottom]}>
                {t('ONE_SIGNAL_NO_SUPPORT_FOR_PUSH_NOTIFICATIONS_DIALOG_HEADING')}
              </BATText>

              <BATText customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottomDouble]}>
                <Trans
                  i18nKey="ONE_SIGNAL_NO_SUPPORT_FOR_PUSH_NOTIFICATIONS_DIALOG_TEXT"
                  components={{
                    Link: (
                      <Text
                        style={{ textDecorationLine: 'underline', color: colors.violet }}
                        onPress={() => {
                          setShowNoSupportForPushNotificationsDialog(false);
                          linkTo('/cum-activez-notificarile-de-tip-push');
                        }}
                      />
                    ),
                  }}
                />
              </BATText>
            </View>
          }
          bodyStyles={{ paddingHorizontal: 16, paddingVertical: 0 }}
          onDismiss={() => {
            setShowNoSupportForPushNotificationsDialog(false);
          }}
        />
      </>
    )
  );
};
