import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStackNavigator } from '@react-navigation/stack';

import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';
import { ScreenNames } from '../ScreenNames';

const ProductsCategories = protectedLazyScreen(() => import('./ProductsCategories'));
const ProductsCategory = protectedLazyScreen(() => import('./ProductsCategory'));
const ProductDetails = protectedLazyScreen(() => import('./ProductView'));
const KentModeInfinite2 = protectedLazyScreen(() => import('./KentModeInfinite2'));
const GloHyperProProduct = protectedLazyScreen(() => import('./GloHyperProProduct'));
const VeloProduct = protectedLazyScreen(() => import('./VeloProduct'));

const Stack = createStackNavigator();

const ProductsStack = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator initialRouteName={ScreenNames.products} screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.products}
        key={ScreenNames.products}
        options={{ title: t('PRODUCTS_TITLE') }}
        component={ProductsCategories}
      />
      <Stack.Screen
        name={ScreenNames.productCategories}
        key={ScreenNames.productCategories}
        options={{ title: t('PRODUCT_CATEGORIES') }}
        component={ProductsCategory}
      />
      <Stack.Screen
        name={ScreenNames.productDetails}
        options={{ title: t('PRODUCT_DETAILS') }}
        component={ProductDetails}
      />
      <Stack.Screen
        name={ScreenNames.kentModeInfinite2}
        key={ScreenNames.kentModeInfinite2}
        options={{ title: 'Kent Mode Infinite Possibilities', headerShown: false }}
        component={KentModeInfinite2}
      />
      <Stack.Screen
        name={ScreenNames.gloHyperProProduct}
        key={ScreenNames.gloHyperProProduct}
        options={{ title: 'Glo Hyper', headerShown: false }}
        component={GloHyperProProduct}
      />
      <Stack.Screen
        name={ScreenNames.veloProduct}
        key={ScreenNames.veloProduct}
        options={{ title: 'Velo', headerShown: false }}
        component={VeloProduct}
      />
    </Stack.Navigator>
  );
};

export { ProductsStack };
