import OneSignal from 'react-onesignal';
import { API_URL, ENVIRONMENT, ONE_SIGNAL_APP_ID } from 'react-native-dotenv';
import moment from 'moment';

import { GetOneSignalTagsResponse } from '../models/OneSignal.model';

export const initOneSignal = () => {
  return OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: ENVIRONMENT === 'localhost' ? true : undefined,
  });
};

export const loginOneSignal = async (externalId: string, consent: boolean | null) => {
  await OneSignal.login(externalId);
  OneSignal.User.addTags({
    has_external_id: '1',
    consent: consent === null ? '2' : consent ? '1' : '0',
  });

  setTimeout(() => {
    OneSignal.Slidedown.promptPush();
  }, 5000);
};

export const logoutOneSignal = () => {
  window.localStorage.removeItem('enableDeviceNotificationsDialogDismissed');
  window.localStorage.removeItem('enablePrivacyNotificationsDialogDismissed');
  window.localStorage.removeItem('oneSignalTagsLastUpdateTimestamp');
  return OneSignal.logout();
};

export const updateOneSignalTags = async (token: string | null, consumerId: string | null) => {
  if (!token || !consumerId) {
    return;
  }

  const oneSignalTagsLastUpdateTimestamp = window.localStorage.getItem(
    'oneSignalTagsLastUpdateTimestamp',
  );

  if (
    !oneSignalTagsLastUpdateTimestamp ||
    moment().unix() > +oneSignalTagsLastUpdateTimestamp + 24 * 3600
  ) {
    window.localStorage.setItem('oneSignalTagsLastUpdateTimestamp', moment().unix().toString());

    try {
      const response = await fetch(`${API_URL}api/onesignal-tags`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          Consumerid: consumerId,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const {
        ap,
        lp,
        lp_glo,
        lp_vuse,
        lp_velo,
        lp_dh,
        lp_kt,
        lp_pm,
        tcap,
        tclp,
        tclp_glo,
        tclp_vuse,
        tclp_velo,
        tclp_dh,
        tclp_kt,
        tclp_pm,
        total_allocated_chances,
        total_prizes_redeemed,
        pack_codes_brand,
        pack_codes_range,
        taste_profile_answers,
      } = await (response.json() as Promise<GetOneSignalTagsResponse>);

      const brandAbbreviations: Record<string, string> = {
        Glo: 'glo',
        Vuse: 'vuse',
        Velo: 'velo',
        Dunhill: 'dh',
        Kent: 'kt',
        'Pall Mall': 'pm',
      };

      const pcb = Object.entries(pack_codes_brand).reduce(
        (pcb, [brand, noOfCodes]) => {
          const brandAbbreviation = brandAbbreviations[brand];
          if (brandAbbreviation) {
            pcb[`pcb_${brandAbbreviation}`] = +noOfCodes;
          }
          return pcb;
        },
        {} as Record<string, number>,
      );

      const pcr = Object.entries(pack_codes_range)
        .filter(([range]) => range !== '')
        .reduce(
          (pcr, [range, noOfCodes]) => {
            const normalizedRange = range
              .replace(/_+/g, ' ')
              .replace(/[^a-z0-9 ]/gi, '')
              .replace(/\s+/g, '_')
              .toLowerCase();
            pcr[`pcr_${normalizedRange}`] = +noOfCodes;
            return pcr;
          },
          {} as Record<string, number>,
        );

      let tst_x_f = {};
      if (!Array.isArray(taste_profile_answers)) {
        tst_x_f = ['fmc', 'hp', 'mw', 'modi', 'ecig'].reduce(
          (tst_x_f, x) => {
            const frequencyOptions = [
              'Cel puțin o dată pe zi',
              'De câteva ori pe săptămână, dar nu zilnic',
              'De câteva ori pe lună, dar nu săptămânal',
              'Mai rar de o dată pe lună',
              'Nu folosesc acest tip de produs',
            ];
            const tasteProfileAnswersProp = `taste_${x}` as
              | 'taste_fmc'
              | 'taste_hp'
              | 'taste_mw'
              | 'taste_modi'
              | 'taste_ecig';
            const frequencyNumber =
              frequencyOptions.indexOf(taste_profile_answers[tasteProfileAnswersProp]) + 1;

            if (frequencyNumber !== 0 && frequencyNumber !== 5) {
              tst_x_f[`tst_${x}_f`] = frequencyNumber;
            }

            return tst_x_f;
          },
          {} as Record<string, number>,
        );
      }

      let tst_x_f_v = {};
      if (!Array.isArray(taste_profile_answers)) {
        const frequencyValueProps = [
          'fmc_usage_adc',
          'fmc_usage_awc',
          'hp_adc',
          'hp_awc',
          'mw_adc',
          'mw_awc',
          'mw_amc',
          'modi_awc',
          'modi_amc',
          'ecig_usage_daily',
          'ecig_usage_weekly',
          'ecig_usage_monthly',
        ].filter((prop) => Object.keys(taste_profile_answers).includes(prop));

        tst_x_f_v = ['fmc', 'hp', 'mw', 'modi', 'ecig'].reduce(
          (tst_x_f_v, x) => {
            const tasteProfileAnswersProp = frequencyValueProps.find(
              (prop) => prop.split('_')[0] === x,
            );

            if (tasteProfileAnswersProp) {
              tst_x_f_v[`tst_${x}_f_v`] =
                +taste_profile_answers[
                  tasteProfileAnswersProp as keyof typeof taste_profile_answers
                ]!;
            }

            return tst_x_f_v;
          },
          {} as Record<string, number>,
        );
      }

      const tst_x_other: Record<string, number | string> = {};
      if (!Array.isArray(taste_profile_answers)) {
        if (
          taste_profile_answers.taste_mw_level_of_nicotine &&
          taste_profile_answers.taste_mw_level_of_nicotine !== 'Nu știu'
        ) {
          const levelsOfNicotine = taste_profile_answers.taste_mw_level_of_nicotine
            .match(/\d+/g)!
            .map(Number);
          tst_x_other.tst_mw_n_min = Math.min(...levelsOfNicotine);
          tst_x_other.tst_mw_n_max = Math.max(...levelsOfNicotine);
        }

        if (taste_profile_answers.taste_modi_nicotine_usage) {
          const modiNicotineUsageNumericValues: Record<string, number> = {
            'Cu nicotină': 1,
            'Fără nicotină': 2,
            Ambele: 3,
          };
          const tst_modi_n =
            modiNicotineUsageNumericValues[taste_profile_answers.taste_modi_nicotine_usage];
          if (tst_modi_n) {
            tst_x_other.tst_modi_n = tst_modi_n;
          }
        }

        [
          ['tst_fmc_b', 'taste_fmc_brand'],
          ['tst_fmc_sku', 'taste_fmc_sku'],
          ['tst_fmc_o_b', 'taste_fmc_other_brands'],
          ['tst_fmc_o_sku', 'taste_fmc_other_sku'],
          ['tst_hp_d', 'taste_hp_devices_usage'],
          ['tst_hp_c', 'taste_hp_consumables_mob'],
          ['tst_mw_b', 'taste_mw_brand'],
          ['tst_modi_b', 'taste_modi_brand'],
          ['tst_ecig_b', 'taste_ecig_brand'],
        ].forEach(([tstOtherTag, tasteProfileAnswersProp]) => {
          const tasteProfileAnswersValue =
            taste_profile_answers[tasteProfileAnswersProp as keyof typeof taste_profile_answers];
          if (tasteProfileAnswersValue) {
            tst_x_other[tstOtherTag] = tasteProfileAnswersValue;
          }
        });
      }

      const oneSignalTags = {
        ap, // activity points
        lp, // loyalty points
        lp_glo, // loyalty points Glo
        lp_vuse, // loyalty points Vuse
        lp_velo, // loyalty points Velo
        lp_dh, // loyalty points Dunhill
        lp_kt, // loyalty points Kent
        lp_pm, // loyalty points Pall Mall
        tcap, // total consumed activity points, the last 6 months
        tclp, // total consumed loyalty points, the last 6 months
        tclp_glo, // total consumed loyalty points Glo, the last 6 months
        tclp_vuse, // total consumed loyalty points Vuse, the last 6 months
        tclp_velo, // total consumed loyalty points Velo, the last 6 months
        tclp_dh, // total consumed loyalty points Dunhill, the last 6 months
        tclp_kt, // total consumed loyalty points Kent, the last 6 months
        tclp_pm, // total consumed loyalty points Pall Mall, the last 6 months
        tac: total_allocated_chances, // total allocated chances, the last 6 months
        tpr: total_prizes_redeemed, // total prizes redeemed, the last 6 months
        ...pcb, // no. of pack codes for each brand (pcb_glo, pcb_kt etc.)
        ...pcr, // no. of pack codes for each range (pcr_mini_mellow_6mg_spiffy_spearmint, pcr_kent_nano etc.)
        ...tst_x_f, // Taste profile frequency for each type: tst_fmc_f, tst_hp_f, tst_mw_f, tst_modi_f or tst_ecig_f (1 - Cel puțin o dată pe zi, 2 - De câteva ori pe săptămână, dar nu zilnic, 3 - De câteva ori pe lună, dar nu săptămânal, 4 - Mai rar de o dată pe lună)
        ...tst_x_f_v, // Taste profile frequency value for each type: tst_fmc_f_v, tst_hp_f_v, tst_mw_f_v, tst_modi_f_v or tst_ecig_f_v
        ...tst_x_other, // Taste profile other responses (more details below)
        /*
          tst_fmc_b - FMC most used brand
          tst_fmc_sku - FMC most used SKU
          tst_fmc_o_b - FMC other brand
          tst_fmc_o_sku - FMC other SKU
          tst_hp_d - HP most used device (possible custom text added by user)
          tst_hp_c - HP most used consumable (possible custom text added by user)
          tst_mw_b - MW most used brands (separated by " | ", possible custom text added by user)
          tst_mw_n_min - MW min level of nicotine
          tst_mw_n_max - MW max level of nicotine
          tst_modi_b - MODI most used brands (separated by " | ")
          tst_modi_n - MODI nicotine usage (1 - Cu nicotină, 2 - Fără nicotină, 3 - Ambele)
          tst_ecig_b - ECIG brands (custom text added by user)
        */
      };

      const oneSignalStringTags = Object.entries(oneSignalTags).reduce(
        (oneSignalStringTags, [key, value]) => {
          oneSignalStringTags[key] = value.toString();
          return oneSignalStringTags;
        },
        {} as Record<string, string>,
      );
      OneSignal.User.addTags(oneSignalStringTags);
    } catch (error) {
      window.localStorage.removeItem('oneSignalTagsLastUpdateTimestamp');
    }
  }
};
