import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { CardProps } from '../../../src/components/BATCard';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { minFieldPoints } from '../../Prizes/components/utils';
import { formatDiscountProps } from '../../Prizes/slices/prizesSlice';
import { ARTICLE_TYPE } from '../../../models/Feed.model';
import { getTimeDifference } from '../../Campaigns/utils';

export enum EntityType {
  ARTICLES = 'articole',
  PRIZES = 'premii',
  PRODUCTS = 'produse',
  CAMPAIGNS = 'campanii',
}

export const formatDate = (dateString: string) => {
  const difMillisec = (new Date(dateString + 'T23:59:59') as any) - (new Date() as any);

  const minutes = Math.floor(difMillisec / (60 * 1000));
  const hours = Math.floor(difMillisec / (60 * 60 * 1000));
  const days = Math.floor(difMillisec / (24 * 60 * 60 * 1000));

  return `${days}d ${hours % 24}h ${minutes % 60}m`;
};

const selectVariant = (chances: number | undefined, stock: number | undefined) => {
  if (stock === 0 || (chances !== undefined && chances >= 0))
    return stock === 0 ? 'danger' : chances !== undefined && chances > 0 ? 'success' : 'warning';
  return undefined;
};

const formatLabel = (chances: number | undefined, stock: number | undefined) => {
  if (stock === 0 || (chances !== undefined && chances >= 0))
    return stock === 0
      ? 'Stoc epuizat'
      : chances === 0
      ? 'Alocă șanse'
      : chances === 1
      ? '1 șansă'
      : `${chances} șanse`;
  return undefined;
};

const isGuaranteedPrize = (entityData: any, prizeType?: string) => {
  return (
    prizeType === 'guaranteed' ||
    entityData?.type?.includes('Guaranteed') ||
    prizeType === 'bundle' ||
    entityData?.type?.includes('Bundle')
  );
};

const formatEntityData = (entityData: any, entityType: any, prizeType?: string) => {
  switch (entityType) {
    case EntityType.ARTICLES:
      return {
        nid: entityData.nid,
        image: entityData.field_image,
        category: entityData.field_content_territory || entityData.category,
        title: entityData.title,
        date: moment(entityData.created, 'DD/MM/YYYY ', 'ro').format('DD MMM YYYY'),
        brand: entityData.field_brand,
        authorName: entityData.field_author,
        authorImage: entityData.author_image,
        url_alias: `/articole${entityData?.brand_url_alias}${
          entityData?.article_type === ARTICLE_TYPE.product
            ? '/produs'
            : entityData?.territory_url_alias || entityData?.url_alias
        }${entityData?.url_alias}`,
      };
    case EntityType.PRIZES:
      return {
        nid: entityData.nid,
        image: entityData.field_image,
        title: entityData.title,
        brand: entityData.field_brand,
        activityPoints:
          'field_activity_points' in entityData && Number(entityData.field_activity_points) > 0
            ? Number(entityData.field_activity_points)
            : undefined,
        loyaltyPoints:
          'field_pack_points' in entityData && Number(entityData.field_pack_points) > 0
            ? Number(entityData.field_pack_points)
            : undefined,
        badge: {
          variant: selectVariant(
            'chances' in entityData ? Number(entityData.chances) : undefined,
            ('field_stock' in entityData &&
              isGuaranteedPrize(entityData, prizeType) &&
              (entityData.field_stock === '' || Number(entityData.field_stock) === 0)) ||
              ('field_redeem_limit' in entityData &&
                entityData.field_redeem_limit !== '' &&
                Number(entityData.field_redeem_limit) === 0)
              ? 0
              : Number(entityData.field_stock) > 0
              ? Number(entityData.field_stock)
              : undefined,
          ),
          label: formatLabel(
            'chances' in entityData ? Number(entityData.chances) : undefined,
            ('field_stock' in entityData &&
              isGuaranteedPrize(entityData, prizeType) &&
              (entityData.field_stock === '' || Number(entityData.field_stock) === 0)) ||
              ('field_redeem_limit' in entityData &&
                entityData.field_redeem_limit !== '' &&
                Number(entityData.field_redeem_limit) === 0)
              ? 0
              : Number(entityData.field_stock) > 0
              ? Number(entityData.field_stock)
              : undefined,
          ),
        },
        stock:
          ('field_stock' in entityData &&
            isGuaranteedPrize(entityData, prizeType) &&
            (entityData.field_stock === '' || Number(entityData.field_stock) === 0)) ||
          ('field_redeem_limit' in entityData &&
            entityData.field_redeem_limit !== '' &&
            Number(entityData.field_redeem_limit) === 0)
            ? 0
            : Number(entityData.field_stock) > 0
            ? Number(entityData.field_stock)
            : undefined,
        url_alias: `/premiu/${
          entityData?.type?.includes('Limited')
            ? 'limitat'
            : entityData?.type?.includes('Guaranteed')
            ? 'garantat'
            : entityData?.type?.includes('Bundle')
            ? 'garantat/bundle'
            : 'moment'
        }${entityData.url_alias}`,
        isBundle: prizeType === 'bundle',
        displayPoints: entityData.displayPoints ?? 'default',
        ...formatDiscountProps(entityData, entityData.type?.toLowerCase()),
      };
    case EntityType.PRODUCTS:
      return {
        nid: entityData.nid,
        image: entityData.field_image,
        category: entityData.field_product_range || entityData.category,
        title: entityData.title,
        brand: entityData.field_brand || entityData.parent_category,
        date: moment(entityData.created, 'DD/MM/YYYY ', 'ro').format('DD MMM YYYY'),
        url_alias: `/produse${entityData?.categorySlug}${entityData.url_alias}`,
      };
    case EntityType.CAMPAIGNS:
      let untilDate =
        'date' in entityData
          ? `${entityData.date.days}d ${entityData.date.hours}h ${entityData.date.minutes}m`
          : 'period' in entityData
          ? formatDate(entityData.period.end)
          : '';
      //Teaser
      if (untilDate === '' && 'campaign_start_date' in entityData) {
        const { days, hours, minutes } = getTimeDifference(entityData.campaign_start_date);
        untilDate = `${days}d ${hours}h ${minutes}m`;
      }
      return {
        nid: entityData.nid || entityData.id,
        image: entityData.field_image || entityData.header,
        title: entityData.title,
        date: untilDate,
        brand: entityData.field_brand || entityData.brand,
        url_alias: `/campanie${entityData.url_alias}`,
      };
    default:
      return {};
  }
};

function isCardProps(obj: any): obj is CardProps {
  const requiredKeys: (keyof CardProps)[] = ['nid', 'image', 'title', 'brand'];

  if (!obj || typeof obj !== 'object') {
    return false;
  }

  for (const key of requiredKeys) {
    if (!(key in obj)) {
      return false;
    }
  }

  return true;
}

const getInitialState = () => ({
  articole: [] as CardProps[],
  premii: [] as CardProps[],
  produse: [] as CardProps[],
  campanii: [] as CardProps[],
});

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: getInitialState(),
  reducers: {
    setFavorites: (state, action) => {
      state[EntityType.PRODUCTS] = [];
      if (action.payload.data.product) {
        state[EntityType.PRODUCTS] =
          action.payload.data.product?.map((product: any) =>
            formatEntityData(product, EntityType.PRODUCTS),
          ) || [];
      }

      state[EntityType.CAMPAIGNS] = [];
      if (action.payload.data.campaign) {
        state[EntityType.CAMPAIGNS] =
          action.payload.data.campaign.map((campaign: any) =>
            formatEntityData(campaign, EntityType.CAMPAIGNS),
          ) || [];
      }

      state[EntityType.ARTICLES] = [];
      if (action.payload.data.article) {
        state[EntityType.ARTICLES] =
          action.payload.data.article.map((article: any) =>
            formatEntityData(article, EntityType.ARTICLES),
          ) || [];
      }

      const allPrizes = [];

      if (action.payload.data['limited prize']) {
        allPrizes.push(
          ...action.payload.data['limited prize'].map((prize: any) =>
            formatEntityData(prize, EntityType.PRIZES),
          ),
        );
      }

      if (action.payload.data['lucky Moments Campaign']) {
        allPrizes.push(
          ...action.payload.data['lucky Moments Campaign'].map((prize: any) =>
            formatEntityData(prize, EntityType.PRIZES),
          ),
        );
      }

      if (action.payload.data['bundle']) {
        allPrizes.push(
          ...action.payload.data['bundle'].map((prize: any) =>
            formatEntityData(
              { ...prize, ...minFieldPoints({ ...prize }) },
              EntityType.PRIZES,
              'bundle',
            ),
          ),
        );
      }

      if (action.payload.data['guaranteed prize']) {
        allPrizes.push(
          ...action.payload.data['guaranteed prize'].map((prize: any) =>
            formatEntityData(prize, EntityType.PRIZES, 'guaranteed'),
          ),
        );
      }

      state[EntityType.PRIZES] = allPrizes;
    },
    toggleFavorite: (state, action) => {
      const entityId = action.payload.entityId;
      const entityType = action.payload.entityType;
      const entityData = action.payload.entityData;
      const isFavorite = action.payload.isFavorite;

      if (isFavorite) {
        state[entityType as keyof typeof state] = state[entityType as keyof typeof state].filter(
          (item) => item?.nid?.toString() !== entityId.toString(),
        );
      } else {
        if (isCardProps(entityData)) {
          state[entityType as keyof typeof state].unshift(entityData);
        } else {
          state[entityType as keyof typeof state].unshift(
            formatEntityData(entityData, entityType) as CardProps,
          );
        }
      }
    },
    increaseChances: (state, action) => {
      const entityId = action.payload.entityId;
      const entityData = action.payload.entityData;

      const prize = state[EntityType.PRIZES].find((prize) => prize.nid === entityId);

      if (prize) {
        state[EntityType.PRIZES] = state[EntityType.PRIZES].filter((item) => item.nid !== entityId);
        state[EntityType.PRIZES].unshift(
          formatEntityData(entityData, EntityType.PRIZES) as CardProps,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const { setFavorites, toggleFavorite, increaseChances } = favoritesSlice.actions;
export default favoritesSlice.reducer;
