import moment from 'moment';
import {
  UserContentType,
  DefaultButtonProps,
  CampaignPeriods,
  PeriodType,
} from '../../../models/Campaigns.model';
import { DueDate } from '../../../models/common';

const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm', '.flv'];

export const detectMediaType = (uri: string) => {
  const getExtension = (uri: string): string | undefined => {
    if (!uri) {
      return undefined;
    }

    const parts = uri.split('.');
    if (parts.length > 1) {
      return parts.pop()?.toLowerCase();
    }
    return undefined;
  };

  const fileExtension = getExtension(uri);

  if (imageExtensions.includes(`.${fileExtension}`)) {
    return 'image';
  } else if (videoExtensions.includes(`.${fileExtension}`)) {
    return 'video';
  } else {
    return 'unknown';
  }
};

export const getCurrentPeriod = (periods: CampaignPeriods): PeriodType => {
  const now = moment();
  const [campaignStart, campaignEndRaw] = periods.campaign_period
    .split(' - ')
    .map((date) => moment(date, 'YYYY-MM-DD'));
  const campaignEnd = campaignEndRaw.add(1, 'day');

  const uploadPeriod =
    periods.upload_period && periods.upload_period !== ' - '
      ? periods.upload_period.split(' - ').map((date) => moment(date, 'YYYY-MM-DD'))
      : null;

  const votingPeriod =
    periods.voting_period && periods.voting_period !== ' - '
      ? periods.voting_period.split(' - ').map((date) => moment(date, 'YYYY-MM-DD'))
      : null;

  if (
    uploadPeriod &&
    votingPeriod &&
    uploadPeriod[1].isSame(votingPeriod[0], 'day') &&
    now.isAfter(uploadPeriod[1]) &&
    now.isSameOrBefore(votingPeriod[1])
  ) {
    return 'upload';
  }

  if (uploadPeriod && now.isBetween(uploadPeriod[0], uploadPeriod[1].add(1, 'day'))) {
    return 'upload';
  }

  if (votingPeriod && now.isBetween(votingPeriod[0], votingPeriod[1].add(1, 'day'))) {
    return 'voting';
  }

  if (votingPeriod && now.isAfter(votingPeriod[1]) && now.isBefore(campaignEnd)) {
    return 'after-voting';
  }

  if (uploadPeriod && votingPeriod && now.isBetween(campaignStart, campaignEnd)) {
    return 'campaign-buffer';
  }

  if (now.isBetween(campaignStart, campaignEnd)) {
    return 'campaign';
  }

  return null;
};

interface DefineButtonPropsByPeriodsProps {
  campaignType: UserContentType;
  periods: CampaignPeriods;
  defaultButton: DefaultButtonProps;
}

export const defineButtonPropsByPeriods = ({
  campaignType,
  periods,
  defaultButton,
}: DefineButtonPropsByPeriodsProps): { label: string; image?: string } => {
  if (defaultButton.cta) {
    return { label: defaultButton.cta, image: defaultButton.cta_icon };
  }

  const currentPeriod = getCurrentPeriod(periods);

  switch (currentPeriod) {
    case 'upload':
      return { label: 'Încarcă moment', image: undefined };
    case 'voting':
      return { label: 'Votează moment', image: undefined };
    case 'campaign-buffer':
    case 'after-voting':
      return { label: 'Vezi detalii', image: undefined };
    default:
      return { label: '', image: '' };
  }
};

export const isDueDate = (obj: any): obj is DueDate => {
  return obj && typeof obj === 'object' && 'days' in obj && 'hours' in obj && 'minutes' in obj;
};

export const getTimeDifference = (date: string | DueDate) => {
  if (isDueDate(date)) {
    return {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    };
  }

  const targetDate = moment(date, ['DD MMM YYYY', moment.ISO_8601], true);

  if (!targetDate.isValid()) {
    return {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    };
  }

  const now = moment();

  const diffMilliseconds = targetDate.diff(now);

  const duration = moment.duration(diffMilliseconds);

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24);
  const minutes = Math.floor(duration.asMinutes() % 60);
  const seconds = Math.floor(duration.asSeconds() % 60);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
