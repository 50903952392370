import { PromotedItemType } from './Promoted.model';
import { CallToActionCardProps } from '../src/components/common/CallToActionCard';
import { DueDate } from './common';
import { CardProps } from '../src/components/BATCard';

export enum ARTICLE_TYPE {
  'simple' = 'Simple article',
  'product' = 'Product article',
}

export type BrandName = 'glo' | 'Dunhill' | 'Kent' | 'Pall Mall';

export enum WidgetTypes {
  OnlyImage = 'Only Image',
  Carousel = 'Carousel',
  LargeImageWithTitleAndCta = 'Large Image with title and CTA',
  SmallIconWithTitleDescriptionAndCta = 'Small icon with title, description and CTA',
  TitleAndCta = 'Title and CTA',
  TitleDescriptionAndCta = 'Title, description and CTA',
}

interface TopArticle {
  author_id: string;
  author_image: string;
  body: string;
  brand_url_alias: string;
  content_type: string;
  created: string;
  field_author: string;
  field_brand: string;
  field_image: string;
  field_linked_content: string;
  field_video_link: string;
  is_read: string;
  linked_content_territory_id: string;
  linked_content_territory_name: string;
  linked_content_territory_url_alias: string;
  linked_content_type: string;
  linked_content_url_alias: string;
  nid: string;
  territory_id: string;
  territory_name: string;
  territory_url_alias: string;
  title: string;
  url_alias: string;
  article_type: string;
}

export interface HomepageWidget {
  nid: string;
  field_always_show: string;
  field_cta_link: string;
  field_cta_text?: string;
  field_homepage_carousel_section?: string;
  field_media_image?: string;
  field_widget_position: string;
  field_widget_type: string;
  field_widget_type_content: string;
  title?: string;
  description?: string;
  field_brand: string;
  carousel_sections?:
    | {
        id: number;
        title: string;
        description: string;
        image: string;
        link: string;
      }[]
    | null;
}

export type HomepageWidgetUi = {
  data: CallToActionCardProps | CardProps | CardProps[];
  type: WidgetTypes;
};

interface HomeFeedCarouselItem {
  nid: string;
  type: PromotedItemType;
  title: string;
  created: string;
  field_brand: string;
  field_image: string;
  url_alias: string;
  activity_points: string;
  loyalty_points: string;
  brand_url_alias: string;
  territory_url_alias: string;

  date?: DueDate;

  is_read?: string;
  article_points?: string;
  collection_title?: string;

  chances?: number;

  always_show?: true;

  product_range?: string;
  product_range_parent?: string;
  product_range_url_alias?: string;
  article_type: string;
}

export interface HomeFeedModel {
  carousel: Record<string, HomeFeedCarouselItem[]>;
  top_articles: Record<BrandName, TopArticle[]>;
  homepage_widgets: Record<BrandName, HomepageWidget[]>;
}

export type NotificationData = {
  nid: string;
  type: string;
  template: {
    title: string;
    body: string;
    cta_text_teaser: string;
    cta_link_external: string;
    cta_text: string;
    image: string;
    pop_up_type: string;
    video: string;
    isSquare?: boolean;
  };
  created: string;
  status: number;
};

export type NotificationModel = {
  data: NotificationData[];
  total: {
    read: number;
    unread: number;
  };
};

export type NotificationsModel = {
  notifications: NotificationModel | [];
  'pop-ups': NotificationModel | [];
  sticky: NotificationModel | [];
  toasts: NotificationModel | [];
};
