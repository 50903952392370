import { useEffect, useState } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useLinkTo } from '@react-navigation/native';
import OneSignal from 'react-onesignal';

import { sharedStyles } from '../../config/theme/shared.styles';
import colors from '../../config/theme/shared/colors';
import { RootState } from '../store';
import { ScreenNames } from '../../screens/ScreenNames';
import BATIcon from './BATIcon';

interface OneSignalButtonProps {
  currentRouteName?: string;
}

export const OneSignalButton = ({ currentRouteName }: OneSignalButtonProps) => {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const [onesignalNotificationPromptDismissed, setOnesignalNotificationPromptDismissed] =
    useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonBottomOffset, setButtonBottomOffset] = useState(0);

  const { width } = useWindowDimensions();
  const linkTo = useLinkTo();

  useEffect(() => {
    const onesignalNotificationPromptInterval = setInterval(() => {
      const onesignalNotificationPrompt = window.localStorage.getItem(
        'onesignal-notification-prompt',
      );
      const onesignalNotificationPromptDismissed =
        !!onesignalNotificationPrompt &&
        JSON.parse(onesignalNotificationPrompt).value === '"dismissed"';

      if (onesignalNotificationPromptDismissed || Notification.permission !== 'default') {
        setOnesignalNotificationPromptDismissed(true);
        clearInterval(onesignalNotificationPromptInterval);
      }
    }, 5000);

    return () => {
      clearInterval(onesignalNotificationPromptInterval);
    };
  }, []);

  useEffect(() => {
    setShowButton(
      !!(
        userData &&
        userData.ageVerified &&
        onesignalNotificationPromptDismissed &&
        (userData.consents.pushNotificationsConsent === null ||
          (userData.consents.pushNotificationsConsent && Notification.permission !== 'granted'))
      ),
    );
  }, [
    userData?.consumerId,
    userData?.ageVerified,
    userData?.consents?.pushNotificationsConsent,
    onesignalNotificationPromptDismissed,
    Notification.permission,
  ]);

  useEffect(() => {
    if (!currentRouteName) {
      return;
    }

    setShowButton(
      !!(
        userData &&
        userData.ageVerified &&
        onesignalNotificationPromptDismissed &&
        (userData.consents.pushNotificationsConsent === null ||
          (userData.consents.pushNotificationsConsent && Notification.permission !== 'granted'))
      ),
    );
    setButtonBottomOffset(0);

    if ([ScreenNames.howActivatePushNotifications].includes(currentRouteName)) {
      setShowButton(false);
      return;
    }

    if (
      [
        ScreenNames.feed,
        ScreenNames.products,
        ScreenNames.campaignsList,
        ScreenNames.prizeList,
        ScreenNames.wallet,
        ScreenNames.howToEarnPoints,
        ScreenNames.historyPoints,
        ScreenNames.favorites,
        ScreenNames.collections,
      ].includes(currentRouteName)
    ) {
      setButtonBottomOffset(65);
    }

    if ([ScreenNames.onBoarding].includes(currentRouteName)) {
      setButtonBottomOffset(74);
    }

    if ([ScreenNames.tasteProfile].includes(currentRouteName)) {
      setButtonBottomOffset(82);
    }

    if ([ScreenNames.changePassword].includes(currentRouteName)) {
      setButtonBottomOffset(84);
    }

    if ([ScreenNames.redeemCheckout, ScreenNames.insertPackCode].includes(currentRouteName)) {
      setButtonBottomOffset(99);
    }

    if ([ScreenNames.insertBonusCode].includes(currentRouteName)) {
      setButtonBottomOffset(112);
    }
  }, [currentRouteName]);

  const handleOnPress = () => {
    if (userData!.consents.pushNotificationsConsent === null) {
      linkTo('/profil/confidentialitate');
    } else if (userData!.consents.pushNotificationsConsent) {
      if (Notification.permission === 'default') {
        OneSignal.Notifications.requestPermission();
      } else if (Notification.permission === 'denied') {
        linkTo('/cum-activez-notificarile-de-tip-push');
      }
    }
  };

  return (
    showButton && (
      <View
        style={[
          sharedStyles.alignItemsCenter,
          sharedStyles.cursorPointer,
          sharedStyles.justifyContentCenter,
          sharedStyles.fixed,
          {
            backgroundColor: '#e54b4d',
            borderRadius: 24,
            bottom: width < 800 ? 16 + buttonBottomOffset : 10,
            height: 48,
            right: width < 800 ? 16 : 10,
            width: 48,
            zIndex: 2147483646,
          },
        ]}>
        <BATIcon
          name="bell"
          style={{
            borderColor: colors.white,
            borderRadius: '50%',
            borderWidth: 1,
            padding: 4,
          }}
          size={28}
          color={colors.white}
          onPress={handleOnPress}
        />
      </View>
    )
  );
};
