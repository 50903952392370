import Toast, { BaseToast } from 'react-native-toast-message';
import { View } from 'react-native';

import { BATEarnedPointsToast } from '../../src/components/announcements/BATEarnedPointsToast';
import BATIcon from '../../src/components/BATIcon';
import BATAnnounceToast from '../../src/components/common/BATAnnounceToast';
import colors from './shared/colors';

export const toastConfig = {
  error: ({ text1, text2, props, ...rest }: any) => (
    <BaseToast
      {...props}
      {...rest}
      autoHide={false}
      renderLeadingIcon={() => (
        <View
          style={{
            alignItems: 'flex-start',
            marginLeft: 4,
          }}>
          <BATIcon
            name={'info-icon'}
            size={24}
            color={colors.toastError}
            style={{ padding: 4, gap: 8 }}
          />
        </View>
      )}
      renderTrailingIcon={() => (
        <View
          style={{
            alignItems: 'flex-start',
            marginRight: 4,
          }}>
          <BATIcon
            name={'close'}
            size={16}
            color={colors.grey_title}
            onPress={() => Toast.hide()}
            style={{ padding: 4, gap: 8, zIndex: 1 }}
          />
        </View>
      )}
      style={{
        borderLeftColor: colors.toastError,
        backgroundColor: colors.cardsBackground,
        overflow: 'hidden',
        width: '90%',
        height: 'auto',
        paddingVertical: 8,
        gap: 8,
      }}
      contentContainerStyle={{
        flex: 1,
        alignItems: 'flex-start',
        paddingHorizontal: 0,
        rowGap: 2,
        paddingRight: 4,
      }}
      text1Style={{
        fontSize: 16,
        color: colors.text,
        fontWeight: '400',
      }}
      text2Style={{
        fontSize: 12,
        color: colors.text,
        fontWeight: '300',
      }}
      text1={text1}
      text2={text2}
      text1NumberOfLines={100}
      text2NumberOfLines={100}
      onTrailingIconPress={() => Toast.hide()}
    />
  ),
  announce: ({ text1, text2, onPress, props }: any) => (
    <BATAnnounceToast
      text1={text1}
      text2={text2}
      onPress={onPress}
      onHide={() => {
        props.onClose();
      }}
    />
  ),
  earnedPoints: ({ text1, text2 }: any) => (
    <BATEarnedPointsToast text1={text1} text2={text2} onHide={() => Toast.hide()} />
  ),
};
