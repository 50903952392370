import { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useLinkTo } from '@react-navigation/native';
import { Trans, useTranslation } from 'react-i18next';
import OneSignal from 'react-onesignal';

import { sharedStyles } from '../../config/theme/shared.styles';
import colors from '../../config/theme/shared/colors';
import { RootState } from '../store';
import { loginOneSignal } from '../one-signal';
import BATDialog from './common/BATDialog';
import BATText from './common/BATText';
import BATIcon from './BATIcon';

export const OneSignalLogin = () => {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const [showNoSupportForPushNotificationsDialog, setShowNoSupportForPushNotificationsDialog] =
    useState(false);
  const [showEnableDeviceNotificationsDialog, setShowEnableDeviceNotificationsDialog] =
    useState(false);
  const [showEnablePrivacyNotificationsDialog, setShowEnablePrivacyNotificationsDialog] =
    useState(false);

  const { t } = useTranslation();
  const linkTo = useLinkTo();

  useEffect(() => {
    if (userData && userData.ageVerified) {
      loginOneSignal(userData.consumerId, userData.consents.pushNotificationsConsent);

      if (!OneSignal.Notifications.isPushSupported()) {
        const noSupportForPushNotificationsDialogDismissed = window.localStorage.getItem(
          'noSupportForPushNotificationsDialogDismissed',
        );

        if (!noSupportForPushNotificationsDialogDismissed) {
          setTimeout(() => {
            setShowNoSupportForPushNotificationsDialog(true);
          }, 5000);
        }
      } else {
        const onesignalNotificationPrompt = window.localStorage.getItem(
          'onesignal-notification-prompt',
        );
        const onesignalNotificationPromptDismissed =
          !!onesignalNotificationPrompt &&
          JSON.parse(onesignalNotificationPrompt).value === '"dismissed"';
        const enableDeviceNotificationsDialogDismissed = window.localStorage.getItem(
          'enableDeviceNotificationsDialogDismissed',
        );
        if (
          onesignalNotificationPromptDismissed &&
          userData.consents.pushNotificationsConsent &&
          Notification.permission !== 'granted' &&
          !enableDeviceNotificationsDialogDismissed
        ) {
          setTimeout(() => {
            setShowEnableDeviceNotificationsDialog(true);
          }, 5000);
        }

        const enablePrivacyNotificationsDialogDismissed = window.localStorage.getItem(
          'enablePrivacyNotificationsDialogDismissed',
        );
        if (
          userData.consents.pushNotificationsConsent === null &&
          Notification.permission === 'granted' &&
          !enablePrivacyNotificationsDialogDismissed
        ) {
          setTimeout(() => {
            setShowEnablePrivacyNotificationsDialog(true);
          }, 5000);
        }

        OneSignal.Notifications.addEventListener(
          'permissionChange',
          (allowedDeviceNotifications) => {
            if (allowedDeviceNotifications && userData.consents.pushNotificationsConsent === null) {
              window.localStorage.removeItem('enablePrivacyNotificationsDialogDismissed');
              setTimeout(() => {
                setShowEnablePrivacyNotificationsDialog(true);
              }, 5000);
            }
          },
        );
      }
    }
  }, [userData?.consumerId]);

  return (
    <>
      <BATDialog
        visible={showNoSupportForPushNotificationsDialog}
        showAsDrawerOnMobile
        body={
          <View style={sharedStyles.alignItemsCenter}>
            <View
              style={[
                sharedStyles.alignItemsCenter,
                sharedStyles.justifyContentCenter,
                sharedStyles.marginBottomDouble,
                {
                  backgroundColor: colors.blueDark,
                  borderRadius: 24,
                  height: 48,
                  width: 48,
                },
              ]}>
              <BATIcon name="notification" color={colors.grey_title} size={32} />
            </View>

            <BATText
              type="heading-4"
              color={colors.grey_title}
              customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottom]}>
              {t('ONE_SIGNAL_NO_SUPPORT_FOR_PUSH_NOTIFICATIONS_DIALOG_HEADING')}
            </BATText>

            <BATText customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottomDouble]}>
              <Trans
                i18nKey="ONE_SIGNAL_NO_SUPPORT_FOR_PUSH_NOTIFICATIONS_DIALOG_TEXT"
                components={{
                  Link: (
                    <Text
                      style={{ textDecorationLine: 'underline', color: colors.violet }}
                      onPress={() => {
                        setShowNoSupportForPushNotificationsDialog(false);
                        window.localStorage.setItem(
                          'noSupportForPushNotificationsDialogDismissed',
                          '1',
                        );
                        linkTo('/cum-activez-notificarile-de-tip-push');
                      }}
                    />
                  ),
                }}
              />
            </BATText>
          </View>
        }
        bodyStyles={{ paddingHorizontal: 16, paddingVertical: 0 }}
        onDismiss={() => {
          setShowNoSupportForPushNotificationsDialog(false);
          window.localStorage.setItem('noSupportForPushNotificationsDialogDismissed', '1');
        }}
      />

      <BATDialog
        visible={showEnableDeviceNotificationsDialog}
        showAsDrawerOnMobile
        body={
          <View style={sharedStyles.alignItemsCenter}>
            <View
              style={[
                sharedStyles.alignItemsCenter,
                sharedStyles.justifyContentCenter,
                sharedStyles.marginBottomDouble,
                {
                  backgroundColor: colors.blueDark,
                  borderRadius: 24,
                  height: 48,
                  width: 48,
                },
              ]}>
              <BATIcon name="notification" color={colors.grey_title} size={32} />
            </View>

            <BATText
              type="heading-4"
              color={colors.grey_title}
              customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottom]}>
              {t('ONE_SIGNAL_ENABLE_DEVICE_NOTIFICATIONS_DIALOG_HEADING')}
            </BATText>

            <BATText customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottomDouble]}>
              <Trans
                i18nKey="ONE_SIGNAL_ENABLE_DEVICE_NOTIFICATIONS_DIALOG_TEXT"
                components={{
                  Link: (
                    <Text
                      style={{ textDecorationLine: 'underline', color: colors.violet }}
                      onPress={() => {
                        setShowEnableDeviceNotificationsDialog(false);
                        window.localStorage.setItem(
                          'enableDeviceNotificationsDialogDismissed',
                          '1',
                        );
                        linkTo('/cum-activez-notificarile-de-tip-push');
                      }}
                    />
                  ),
                }}
              />
            </BATText>
          </View>
        }
        bodyStyles={{ paddingHorizontal: 16, paddingVertical: 0 }}
        onDismiss={() => {
          setShowEnableDeviceNotificationsDialog(false);
          window.localStorage.setItem('enableDeviceNotificationsDialogDismissed', '1');
        }}
      />

      <BATDialog
        visible={showEnablePrivacyNotificationsDialog}
        showAsDrawerOnMobile
        body={
          <View style={sharedStyles.alignItemsCenter}>
            <View
              style={[
                sharedStyles.alignItemsCenter,
                sharedStyles.justifyContentCenter,
                sharedStyles.marginBottomDouble,
                {
                  backgroundColor: colors.blueDark,
                  borderRadius: 24,
                  height: 48,
                  width: 48,
                },
              ]}>
              <BATIcon name="notification" color={colors.grey_title} size={32} />
            </View>

            <BATText
              type="heading-4"
              color={colors.grey_title}
              customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottom]}>
              {t('ONE_SIGNAL_ENABLE_PRIVACY_NOTIFICATIONS_DIALOG_HEADING')}
            </BATText>

            <BATText customStyle={[sharedStyles.alignCenter, sharedStyles.marginBottomDouble]}>
              <Trans
                i18nKey="ONE_SIGNAL_ENABLE_PRIVACY_NOTIFICATIONS_DIALOG_TEXT"
                components={{
                  Link: (
                    <Text
                      style={{ textDecorationLine: 'underline', color: colors.violet }}
                      onPress={() => {
                        setShowEnablePrivacyNotificationsDialog(false);
                        window.localStorage.setItem(
                          'enablePrivacyNotificationsDialogDismissed',
                          '1',
                        );
                        linkTo('/profil/confidentialitate?scrollToEnd=1');
                      }}
                    />
                  ),
                }}
              />
            </BATText>
          </View>
        }
        bodyStyles={{ paddingHorizontal: 16, paddingVertical: 0 }}
        onDismiss={() => {
          setShowEnablePrivacyNotificationsDialog(false);
          window.localStorage.setItem('enablePrivacyNotificationsDialogDismissed', '1');
        }}
      />
    </>
  );
};
